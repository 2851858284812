.table-cell-with-edit-icon {
  position: relative;
}

.edit-icon {
  position: absolute;
  top: 50%;
  right: 90px;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.table-cell-with-edit-icon:hover .edit-icon {
  opacity: 1;
}
